import React from 'react';

export default function PageFooter() {
  return (
    <div className="container">
      <ul className="copyright">
        <li>
          Software bajo{' '}
          <a
            href="https://github.com/JuanmaDonado/adingenieria.net/blob/master/LICENSE.md"
            target="blank"
          >
            licencia GNU GPLv3
          </a>
        </li>
        <li>
          Diseño:{' '}
          <a href="http://html5up.net" target="blank">
            HTML5 UP
          </a>
        </li>
        <li>
          Creado por{' '}
          <a href="https://github.com/JuanmaDonado/" target="blank">
            Juanma Donado
          </a>
        </li>
      </ul>
    </div>
  );
}
