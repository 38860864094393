import React from 'react';

const Feature = ({ pic, title, children }) => {
  return (
    <article>
      <a href={pic} className="image">
        <img src={pic} alt={title} />
      </a>
      <div className="inner">
        <h4>{title}</h4>
        {children}
      </div>
    </article>
  );
};

export default Feature;
